import React from 'react'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from 'styled-components'
import tw, { css } from 'twin.macro'

import { Article } from '@common/components'
import { MainLayout } from '@common/layouts'
import { PaginationLayout } from '@common/layouts'

export const query = graphql`
query LatestArticles($skip: Int!, $limit: Int!) {
  allArticle(
    sort: { fields: [publishedAt], order: DESC }
    limit: $limit
    skip: $skip
  ) {
    edges {
      node {
        id
        migrationId
        title
        slug
        image {
          childImageSharp {
            fluid {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
        tags {
          id
          name
          slug
        }
        childMarkdownRemark {
          excerpt(pruneLength: 80)
        }
      }
    }
  }
}
`

const ListWrapper = styled.div`
  ${tw`flex-1 grid grid-cols-1 gap-16 my-8 mx-8`}
  ${tw`md:(grid-cols-2 my-12)`}
  ${tw`lg:(grid-cols-3)`}
`

const ImageWrapper = styled.div`
  ${tw`relative w-full mb-4`}
`

const Tags = styled(Article.Tags)`
  ${tw`absolute top-0 right-0 justify-end text-white my-4 mx-2`}
  ${css`
    font-size: 11px;
    li {
      ${tw`bg-white text-black border-black`}
    }
  `}
`

const ArticleListPage = ({ data: { allArticle }, pageContext, Layout = MainLayout }) => {
  const { currentPage, numPages } = pageContext;

  return (
    <PaginationLayout title={`Latest Articles`} path={'latest-articles'} currentPage={currentPage} numPages={numPages} Layout={Layout}>
      <ListWrapper>
        {allArticle.edges.map(({ node: article }) => (
          <Link
            to={`/articles/${article.slug}`}
            title={`Article: ${article.title}`}
            tw="overflow-hidden cursor-pointer"
            key={article.id}
          >
            <article>
              {article.image && (
                <ImageWrapper>
                  <Img
                    tw="h-full w-full"
                    fluid={{
                      ...article.image.childImageSharp.fluid,
                      aspectRatio: 4 / 3,
                    }}
                    alt={article.title}
                  />
                  <Tags tags={article.tags} maxItems={3} />
                </ImageWrapper>
              )}

              <h3 tw="text-xs text-gray-800 mb-2">{article.createdAt}</h3>
              <h2 tw="text-xl text-gray-900 font-bold tracking-tight leading-6 mb-2">
                {article.title}
              </h2>
              <p tw="text-sm text-gray-800 leading-6">
                {article.childMarkdownRemark.excerpt}
              </p>
            </article>
          </Link>
        ))}
      </ListWrapper>
    </PaginationLayout>
  )
}

export default ArticleListPage
